

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';
import { IProduct } from '@/interfaces/product';

export default defineComponent({
    mixins: [Base],
    watch: {
        active(value) {
            if (value) {
                this.getAssociatedProducts();
            }
        },
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: undefined,
        },
    },
    mounted() {

    },
    data() {
        return {
            isAddAssociatedProductsModal: false,
            params: {
                page: 1,
            },
            items: {
                data: [],
                meta: {},
            },
            newAssociatedProduct: {
                product: undefined as any | IProduct,
                units: 1,
            },
        };
    },
    methods: {
        addAssociatedProduct() {
            this.post(`products/${this.$route.params.id}/associated`, {
                ...this.newAssociatedProduct,
                product_id: this.newAssociatedProduct.product ? this.newAssociatedProduct.product.id : null,
            })
                .then(() => {
                    this.getAssociatedProducts();
                    this.isAddAssociatedProductsModal = false;
                });
        },
        deleteAssociatedProduct(id: string) {
            this.delete(`products/${this.$route.params.id}/associated/${id}`)
                .then(() => {
                    this.getAssociatedProducts();
                });
        },
        getAssociatedProducts(page = 1) {
            this.get(`products/${this.$route.params.id}/associated`, {
                params: {
                    ...this.params,
                },
            })
                .then(({ data }) => {
                    this.items.data = data.data;
                    this.items.meta = data.meta;
                });
        },

        saveAssociatedProduct(data: any) {
            this.put(`products/${this.$route.params.id}/associated/${data.id}`, {
                ...data,
            })
                .then(() => {
                    this.getAssociatedProducts();
                    this.isAddAssociatedProductsModal = false;
                });
        },
    },

});
